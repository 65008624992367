<template>
    <div class="py-6">
        <div class="flex justify-center" v-if="results.last_page > 1">
            <button type="button" @click="changePage(1)" class="bg-white border border-gray-300 border-r-0 w-8 h-8" :class="results.current_page === 1 ? 'text-gray-600 cursor-default' : 'hover:bg-gray-200'" title="First Page">
                <i class="fa fa-angle-double-left"></i>
            </button>

            <button type="button" v-for="i in leftPages" :key="i" @click="changePage(i)" class="bg-white hover:bg-gray-200 border border-gray-300 border-r-0 w-8 h-8">
                {{ i }}
            </button>

            <button type="button" class="bg-primary-600 text-white w-8 h-8 cursor-default">
                {{ results.current_page }}
            </button>

            <button type="button" v-for="i in rightPages" :key="i" @click="changePage(i)" class="bg-white hover:bg-gray-200 border border-gray-300 border-l-0 w-8 h-8">
                {{ i }}
            </button>

            <button type="button" @click="changePage(results.last_page)" class="bg-white border border-gray-300 border-l-0 w-8 h-8" title="Last Page" :class="results.current_page === results.last_page ? 'text-gray-600 cursor-default' : 'hover:bg-gray-200'">
                <i class="fa fa-angle-double-right"></i>
            </button>
        </div>

        <div class="text-center text-xs text-gray-600 mt-2">
            {{ results.total | number }} {{ results.total === 1 ? 'result' : 'results' }}
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 1
    },
    results: {
      type: Object,
      required: true
    }
  },
  computed: {
    leftPages () {
      const output = []

      for (let i = 3; i > 0; i--) {
        const j = this.results.current_page - i
        if (j > 0) {
          output.push(j)
        }
      }

      return output
    },
    rightPages () {
      const output = []

      for (let i = 1; i <= 3; i++) {
        const j = this.results.current_page + i
        if (j <= this.results.last_page) {
          output.push(j)
        }
      }

      return output
    }
  },
  methods: {
    changePage (i) {
      this.$emit('input', i)
    }
  }
}
</script>
