<template>
  <div class="w-full" :class="className">
    <div class="flex flex-wrap -mx-2" :class="label ? '' : 'w-full'">
      <div class="flex-grow px-2">
        <input
          type="checkbox"
          :id="id"
          v-model="content"
          @change="handleInput"
          :value="optionValue"
        />
        <label :for="id" v-if="label" class="block w-full">{{ label }}</label>
        <label :for="id" v-else class="block">&nbsp;</label>
        <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
          <i class="fas fa-exclamation-triangle"></i>
          {{ error }}
        </div>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">{{ tooltip }}</tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import randomstring from 'randomstring'

export default {
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {},
    optionValue: {
      default: true
    },
    tooltip: {
      type: String
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  @apply hidden;

  + label {
    @apply cursor-pointer;

    &::before {
      font-family: 'Font Awesome 5 Free';
      @apply text-gray-400 mr-2;
      content: "\F0C8";
    }

    &:hover {
      &::before {
        @apply text-gray-500;
      }
    }
  }

  &:checked {
    + label {
      &::before {
        @apply text-black font-black;
        content: "\F14A";
      }
    }
  }
}
</style>
